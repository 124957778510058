// src/pages/Services.js
import React from 'react';
import { Link } from 'react-router-dom';
import serviceImage1 from '../assets/img/main/ICTservices.jpeg';
import serviceImage2 from '../assets/img/main/techworld.jpeg';
import serviceLeftImage from '../assets/img/main/techworld.jpeg';
import '../assets/css/ictServices.css';
const Services = () => {

  //Email redirect

    // Specify the email address and other details
    const emailAddress = 'gichehat@gmail.com';
    const subject = 'Inquiry';
    const body = 'Hello, I have an enquiry...';

    // Construct the mailto link
    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

  return (
    <>

      <section>
        <div id="content-block">
          <div className="content-push">
            <div className="parallax-slide">
              <div className="parallax-clip">
                <div className="fixed-parallax" style={{ backgroundImage: `url(${serviceImage1})` }}>
                </div>
              </div>
            </div>


            <div className="parallax-slide">
              <div className="parallax-clip">
                <div className="fixed-parallax" style={{ backgroundImage: `url(${serviceImage2})` }}>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* =====================List of ICT Services==========================*/}
      <section className="features-blue">
        <div className="container">
          <div className="intro">
            <h2 className="text-center">ICT Services</h2>
          </div>
          <div className="row features">
            <div className="col-sm-6 col-md-4 item centermobile"><i className="fas fa-code-branch icon mobilecentericon"></i>
              <h3 className="name mobilecentertext">&nbsp;Network Infrastructure</h3>
            </div>
            <div className="col-sm-6 col-md-4 item"><i className="fab fa-creative-commons-share icon mobilecentericon"></i>
              <h3 className="name mobilecentertext">System Analysis</h3>
            </div>
            <div className="col-sm-6 col-md-4 item"><i className="fas fa-laptop-code icon mobilecentericon"></i>
              <h3 class="name mobilecentertext">Consumer Applications</h3>
            </div>
            <div className="col-sm-6 col-md-4 item"><i className="fab fa-gg icon mobilecentericon"></i>
              <h3 className="name mobilecentertext">Cybersecurity Solutions</h3>
            </div>
            <div className="col-sm-6 col-md-4 item"><i className="fas fa-cogs icon mobilecentericon"></i>
              <h3 className="name mobilecentertext">Consultation</h3>
            </div>
          </div>
        </div>
      </section>
      {/* =====================?Image and text component==========================*/}
      <section className='techmain'>
        <div className="container">
          <div className="row flex-column-reverse flex-md-row two-columns">

            <div className="column">
              <img src={serviceLeftImage} alt="pro-ICT services" className="img-fluid" />
            </div>

            <div className="column text-column">
              <h2>Why Choose Us</h2>
              <p>For top-notch ICT services that exceed expectations. We deliver reliable solutions tailored to your needs.</p>
              <Link to={mailtoLink} className="btn btn-primary">Services</Link>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default Services;
