// src/pages/Experts.js
import React from 'react';
import { Link } from 'react-router-dom';
import carbackground from '../assets/img/main/car2.jpg'
import citiExpert from '../assets/img/main/img-1.jpg'
import '../assets/css/extras/expert.css';
const Experts = () => {
    return (
        <div>
            <hr style={{ color: '#fdffff' }} />
            <section id="diagonal" className="container" style={{ maxWidth: '100%' }}>
                <div id="diagonal-text">
                    <p className="container" style={{ textAlign: 'center', color: '#fff'}}>
                        <br />
                        CitiTech support professional growth, allowing experts to maximize their skills and knowledge.
                        Global collaboration and local expertise apply at all levels to provide solutions to different clients.
                        Being part of the team is simple.
                    </p>
                </div>
            </section>
            <hr style={{ color: '#fdffff' }} />
            <div className="container">
                <div className="row">
                    {/* Your expert boxes go here */}
                    <div className="row">
                        <div className="col-sm-6 col-md-4">
                            <div className="box">
                                <img src={citiExpert} alt="Desmond" />
                                <div className="box-heading">
                                    <h4 className="title" style={{ color: '#333333' }}>First-Last Name</h4>
                                    <span className="post" style={{ color: '#333333' }}>Web Designer</span>
                                </div>
                                <div className="boxContent">
                                    <p className="description" style={{ color: '#333333' }}>Chief Technologist</p>
                                    <a className="read" href="#">Read more<i className="fab fa-linkedin-in"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                            <div className="box">
                            <img src={citiExpert} alt="Lee-Ann" />
                                <div className="box-heading">
                                    <h4 className="title" style={{ color: '#333333' }}>First-Last Name</h4>
                                    <span className="post" style={{ color: '#333333' }}>Sales Manager</span>
                                </div>
                                <div className="boxContent">
                                    <p className="description" style={{ color: '#333333' }}>Hero Sales Person</p>
                                    <a className="read" href="#">Read more<i className="fab fa-linkedin-in"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                            <div className="box">
                                <img src={citiExpert} alt="John-John" />
                                <div className="box-heading">
                                    <h4 className="title" style={{ color: '#333333' }}>First-Last Name</h4>
                                    <span className="post" style={{ color: '#333333' }}>Web Developer</span>
                                </div>
                                <div className="boxContent">
                                    <p className="description" style={{ color: '#333333' }}>Full Stack Developer</p>
                                    <a className="read" href="#">Read more<i className="fab fa-linkedin-in"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <hr style={{ color: '#fdffff' }} />
            {/* Paradise Slider */}
            <div
                id="fw_al_001"
                className="carousel slide ps_slide_y ps_indicators_y swipe_y ps_easeOutQuint"
                data-ride="carousel"
                data-pause="hover"
                data-interval="5000"
                data-duration="2500"
            >
                {/* Indicators */}
                <ol className="carousel-indicators">
                    <li data-target="#fw_al_001" data-slide-to="0" className="active"></li>
                    <li data-target="#fw_al_001" data-slide-to="1"></li>
                    <li data-target="#fw_al_001" data-slide-to="2"></li>
                </ol>

                {/* Wrapper For Slides */}
                <div className="carousel-inner" role="listbox">
                    {/* First Slide */}
                    <div className="carousel-item active">
                        {/* Slide Background */}
                        <img src={carbackground} alt="fw_al_001_01" />
                        {/* Slide Text Layer */}
                        <div className="fw_al_001_slide">
                            <h3 data-animation="animated fadeInUp">Experts</h3>
                            <h1 data-animation="animated fadeInUp">Who are they?</h1>
                            <p data-animation="animated fadeInUp" style={{ textAlign: 'center' }}>
                                Those in professional fields ready to unleash their powers and mastery in their fields.
                            </p>
                            <a href="#" data-animation="animated fadeInUp">........</a>
                        </div>
                    </div>
                    {/* End of Slide */}

                    {/* Second Slide */}
                    <div className="carousel-item">
                        {/* Slide Background */}
                        <img src={carbackground} alt="fw_al_001_02" />
                        {/* Slide Text Layer */}
                        <div className="fw_al_001_slide">
                            <h3 data-animation="animated fadeInUp">Experts</h3>
                            <h1 data-animation="animated fadeInUp">Requirements</h1>
                            <p data-animation="animated fadeInUp">
                                To be part of the global team, we need anyone with mastery in their field and no limit to profession or level.
                            </p>
                        </div>
                    </div>
                    {/* End of Slide */}

                    {/* Third Slide */}
                    <div className="carousel-item">
                        {/* Slide Background */}
                        <img src={carbackground} alt="fw_al_001_03" />
                        {/* Slide Text Layer */}
                        <div className="fw_al_001_slide">
                            <h3 data-animation="animated fadeInUp">Experts</h3>
                            <h1 data-animation="animated fadeInUp">Quantum Global Team</h1>
                            <p data-animation="animated fadeInUp">
                                Wondering how to boost your profile? No more worries, simply contact us detailing your professionalism.
                            </p>
                            <a href="#" data-animation="animated fadeInUp">Reach to us</a>
                        </div>
                    </div>
                    {/* End of Slide */}

                </div>
            </div>
            {/* End Paradise Slider */}
        </div>
    );
};

export default Experts;
