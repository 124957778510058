import React from 'react';
// import researchhedder from '../assets/img/major.jpeg';
import researchimage1 from '../assets/img/ctech.jpeg';
import '../assets/css/research.css'
const RDServices = () => {

  //Email redirect

  return (
    <>
      <div className="rdmain" style={{ height: '500px' }}>
        <div style={{ height: 'inherit', minHeight: 'initial', width: '100%', position: 'absolute', left: '0', backgroundColor: 'rgba(30,41,99,0.53)' }} className="d-flex justify-content-center align-items-center">
          <div style={{ height: '200px' }} className="d-flex align-items-center order-5">
            <div className="container">
              <h1 className="text-center" style={{ color: 'rgb(242,245,248)', fontSize: '56px', fontWeight: 'bold', fontFamily: 'Roboto, sans-serif' }}>Research & Data services</h1>
              <h3 className="text-center" style={{ color: 'rgb(242,245,248)', paddingTop: '0.25em', paddingBottom: '0.25em', fontWeight: 'normal' }}>We have specialist human resource ready to help our client in different research related areas.</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        {/* Your card components go here */}
        <div className="card card1">
          <div className="card-body">
            <h3 className="card-title">Proposal Development</h3>
            <p className="card-text small"></p>
            <div className="go-corner">
              <div className="go-arrow"><div className="go-arrow">→</div></div>
            </div>
          </div>
        </div>      <div className="container">
        <div className="card card1">
          <div className="card-body">
            <h3 className="card-title">Review of Relevant Literature</h3>
            <div className="go-corner">
              <div className="go-arrow"><div className="go-arrow">→</div></div>
            </div>
          </div>
        </div>
        <div className="card card1">
          <div className="card-body">
            <h3 className="card-title">Data collection</h3>
            <div className="go-corner">
              <div className="go-arrow"><div className="go-arrow">→</div></div>
            </div>
          </div>
        </div>
        <div className="card card1">
          <div className="card-body">
            <h3 className="card-title">Statistical analysis</h3>
            <div className="go-corner">
              <div className="go-arrow"><div className="go-arrow">→</div></div>
            </div>
          </div>
        </div>
        <div className="card card1">
          <div className="card-body">
            <h3 className="card-title">Copy editing, proofreading and formatting</h3>
            <div className="go-corner">
              <div className="go-arrow"><div className="go-arrow">→</div></div>
            </div>
          </div>
        </div>
        <div className="card card1">
          <div className="card-body">
            <h3 className="card-title">Preparation for publication in refereed journals</h3>
            <div className="go-corner">
              <div className="go-arrow"><div className="go-arrow">→</div></div>
            </div>
          </div>
        </div>
        <div className="card card1">
          <div className="card-body">
            <h3 className="card-title">Data Provision Services</h3>
            <div className="go-corner">
              <div className="go-arrow"><div className="go-arrow">→</div></div>
            </div>
          </div>
        </div>
      </div>

      </div>

      
      <section className="article-list">
        <div className="container">
          <div className="intro">
            <h2 className="text-center">Previous Research</h2>
            <p className="text-center">CitiTech experts have helped clients achieve their research and data services over the past years. Here are samples of our work of art and science.</p>
          </div>
          <div className="row articles">
            <div className="col-sm-6 col-md-4 item"><a href="#"><img className="img-fluid" src={researchimage1} alt="Research Thumbnail" /></a>
              <h3 className="name">Research Title</h3>
              <p className="description">CITITech excels in delivering cutting-edge projects, merging innovation with precision. Our expert team ensures seamless execution, from conceptualization to implementation, guaranteeing optimal outcomes for your unique requirements.</p><a className="action" href="#"><i className="fas fa-arrow-circle-right"></i></a>
            </div>
            <div className="col-sm-6 col-md-4 item"><a href="#"><img className="img-fluid" src={researchimage1} alt="Research Thumbnail" /></a>
              <h3 className="name">Research Title</h3>
              <p className="description">CITITech excels in delivering cutting-edge projects, merging innovation with precision. Our expert team ensures seamless execution, from conceptualization to implementation, guaranteeing optimal outcomes for your unique requirements.</p><a className="action" href="#"><i className="fas fa-arrow-circle-right"></i></a>
            </div>
            <div className="col-sm-6 col-md-4 item"><a href="#"><img className="img-fluid" src={researchimage1} alt="Research Thumbnail" /></a>
              <h3 className="name">Research Title</h3>
              <p className="description">CITITech excels in delivering cutting-edge projects, merging innovation with precision. Our expert team ensures seamless execution, from conceptualization to implementation, guaranteeing optimal outcomes for your unique requirements.</p><a className="action" href="#"><i className="fas fa-arrow-circle-right"></i></a>
            </div>
          </div>
        </div>
      </section>
      <div className="example-box">
        <div className="background-shapes"></div>
        <h1 style={{ color: '#ffffff', textAlign:'center'}}>Research and data services extended</h1>
        <p style={{ color: '#ffffff', textAlign:'center'}}>Combining the previous research and data services, we have an extended list from our publication and more from the users.</p><button className=" border-pretty" type="button">Continue Reading <i className="fas fa-arrow-right"></i></button>
      </div>
    </>
  );
};

export default RDServices;
