// src/pages/Home.js
import React from 'react';
import { Link } from 'react-router-dom';
import becititech from '../assets/img/ctech.jpeg';
import '../assets/css/cmain.css'
const Home = () => {
  return (
    <div>
      <hr />
      {/* Features Section */}
      <section id="carousel">
        <div className="carousel slide" data-bs-ride="carousel" id="carousel-1">
          <div className="carousel-inner">
            <div className="carousel-item">
              <div className="bg-light border rounded border-light pulse animated hero-nature carousel-hero jumbotron py-5 px-4">
                <h1 className="hero-title">CitiTech</h1>
                <p className="hero-subtitle">Pioneers transformative solutions at the intersection of innovation and technology. Unleashing the power of cutting-edge advancements, driving your business into the future of limitless possibilities.</p>
                <p><a className="btn btn-primary hero-button plat" role="button" href="#">Lean more</a></p>
              </div>
            </div>
            <div className="carousel-item">
              <div className="bg-light border rounded border-light pulse animated hero-photography carousel-hero jumbotron py-5 px-4">
                <h1 className="hero-title">Innovate, Transform, Succeed</h1>
                <p className="hero-subtitle">We Innovate seamlessly, transform effortlessly, and pave the way for unparalleled success with our tailored consultancy solutions.</p>
                <p><a className="btn btn-primary hero-button plat" role="button" href="#learnmore">Learn more</a></p>
              </div>
            </div>
            <div className="carousel-item active">
              <div className="bg-light border rounded border-light pulse animated hero-technology carousel-hero jumbotron py-5 px-4">
                <h1 className="hero-title">Growth Catalyst</h1>
                <p className="hero-subtitle">CITI serves as your catalyst, propelling sustainable innovation to ensure a prosperous and thriving future of agricultural, climate services, entrepreneurship, and professional endeavors.</p>
                <p><a className="btn btn-primary hero-button plat" role="button" href="#learnmore">Learn more</a></p>
              </div>
            </div>
          </div>
          <div>
            <a className="carousel-control-prev" href="#carousel-1" role="button" data-bs-slide="prev">
              <span className="carousel-control-prev-icon"></span>
              <span className="visually-hidden">Previous</span>
            </a>
            <a className="carousel-control-next" href="#carousel-1" role="button" data-bs-slide="next">
              <span className="carousel-control-next-icon"></span>
              <span className="visually-hidden">Next</span>
            </a>
          </div>
          <div className="carousel-indicators">
            <button type="button" data-bs-target="#carousel-1" data-bs-slide-to="0"></button>
            <button type="button" data-bs-target="#carousel-1" data-bs-slide-to="1"></button>
            <button type="button" data-bs-target="#carousel-1" data-bs-slide-to="2" className="active"></button>
          </div>
        </div>
      </section>

      {/* Features Boxed Section */}
      <section className="features-boxed">
        <div className="container">
          <div className="intro">
            <h2 className="text-center">Benefit of our Services</h2>
            <p className="text-center citimain">Several operations are incorporated in the system to meet several needs of our users.</p>
          </div>
          <div className="row justify-content-center features">
            <div className="col-sm-6 col-md-5 col-lg-4 item">
              <div className="box"><i className="fas fa-suitcase icon"></i>
                <h3 className="name">Technology Solutions</h3>
                <p className="description">Embrace the future with CITI's technology services. Our network of tech enthusiasts and professionals collaboratively craft solutions tailored to your needs.</p>
                <Link className="learn-more" to="/services">Get started »</Link>
              </div>
            </div>
            <div className="col-sm-6 col-md-5 col-lg-4 item">
              <div className="box"><i className="fas fa-seedling icon"></i>
                <h3 className="name">Agribusiness</h3>
                <p className="description">Embrace the future with CITI's technology services. Our network of tech enthusiasts and professionals collaboratively craft solutions tailored to your needs.</p>
                <Link className="learn-more" to="/agribusiness">Explore Now »</Link>
              </div>
            </div>
            <div className="col-sm-6 col-md-5 col-lg-4 item">
              <div className="box"><i className="fas fa-globe icon"></i>
                <h3 className="name">Data Services</h3>
                <p className="description">In a data-driven world, CITI stands as your ally, optimizing data services for enhanced efficiency. Our collaborative approach ensures that your data is a strategic asset.</p>
                <Link className="learn-more" to="/rdservices">Discover more »</Link>
              </div>
            </div>
            <div className="col-sm-6 col-md-5 col-lg-4 item">
              <div className="box"><i className="fas fa-laptop-code icon"></i>
                <h3 className="name">Research &amp; Development</h3>
                <p className="description">Unleash the power of innovation and problem-solving with CITI's expert network. Our consultancy services propel your research and development endeavors, guiding you through the intricacies of innovation.</p>
                <Link className="learn-more" to="/rdservices">Learn more »</Link>
              </div>
            </div>
            <div className="col-sm-6 col-md-5 col-lg-4 item">
              <div className="box"><i className="fas fa-cloud-sun-rain icon"></i>
                <h3 className="name">Climate Services</h3>
                <p className="description">In a data-driven world, CITI stands as your ally, optimizing data services for enhanced efficiency. Our collaborative approach ensures that your data becomes a strategic asset.</p>
                <Link className="learn-more" to="/environmental">Learn more »</Link>
              </div>
            </div>
            <div className="col-sm-6 col-md-5 col-lg-4 item">
              <div className="box"><i className="fas fa-users icon"></i>
                <h3 className="name">Global Collaboration, Local Expertise</h3>
                <p className="description">&nbsp;Our international network of professionals collaborates seamlessly with local talent, offering a unique blend of perspectives to address your business challenges.</p>
                <Link className="learn-more" to="/experts/diagonal">Connect Now »</Link>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* Row Image Text Section */}
      <div className="row clearmargin clearpadding row-image-txt">
        <div className="col-xs-12 col-sm-6 col-md-6 clearmargin clearpadding col-sm-push-6">
        <img style={{ width: '100%' }} src={becititech} alt="CitiTech" />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-sm-pull-6" style={{ padding: '20px', background: 'linear-gradient(360deg, #4a5aa7 0%, #4a5aa7 53%, #231606 92%)' }}>
          <h3><span style={{ color: 'rgb(255, 255, 255)' }}>Unlocking Tomorrow's Potential</span></h3>
          <hr />
          <p>Harness the power of CITI's diverse services. From cutting-edge technology solutions tailored to your needs, to data optimization, research and development innovation, and climate services ensuring strategic efficiency. Our global collaboration and local expertise offer a unique perspective, seamlessly addressing your business challenges. Connect now to embrace the future with CITI.</p>
          <div style={{ textAlign: 'center' }}></div>
        </div>
      </div>

      <hr />
    </div>
  );
};

export default Home;
