// src/pages/Agribusiness.js
import React from 'react';
import agribizVideo from '../assets/img/main/test-video.mp4';
import agriserviceImage1 from '../assets/img/main/fdb.jpeg';
import agriserviceImage2 from '../assets/img/main/car2.jpg';
import '../assets/css/extras/agribiz.css';
import { Link } from 'react-router-dom';

const Agribusiness = () => {

    // Create an array of image sources
    const imageSources = [agriserviceImage1, agriserviceImage2, agriserviceImage1];

    //Email redirect

    // Specify the email address and other details
    const emailAddress = 'gichehat@gmail.com';
    const subject = 'Inquiry';
    const body = 'Hello, I have an enquiry...';

    // Construct the mailto link
    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;


    return (
        <>
            <section id="main">
                <video src={agribizVideo} muted autoPlay loop></video>
                <div className="main-text">
                    <h2>Agribisiness walk...</h2>
                    <h1>Farm to Market</h1>
                    <p>CitiTech Remains your preferred destination for agribusiness solutions.</p>
                    <Link to="/contact" className="btn">
                        Let's go!
                    </Link>
                </div>
            </section>
            <section>
                <div className='agrintro'><h1>Agribusiness Services</h1></div>
                <div className="container">
                    <div className="row">
                        {[1, 2, 3].map((index) => (
                            <div className="col-md-4" key={index}>
                                <div className="blog-card blog-card-blog">
                                    <div className="blog-card-image">
                                        <a href="#">
                                            <img className="img-fluid img" src={imageSources[index - 1]} alt={`Service ${index}`} />
                                        </a>
                                        <div className="ripple-cont"></div>
                                    </div>
                                    <div className="blog-table">
                                        <h6 className="blog-category blog-text-success">
                                            <i className={`fas ${getCategoryIcon(index)}`}></i>&nbsp;Farm to Market
                                        </h6>
                                        <h4 className="blog-card-caption">
                                            <a href="#">
                                                <br />
                                                {getServiceTitle(index)}
                                                <br />
                                                <br />
                                            </a>
                                        </h4>
                                        <p className="blog-card-description">{getServiceDescription(index)}</p>
                                        <ul className="list-group" style={{ borderStyle: 'none', borderColor: '#ffffff' }}>
                                            {getServiceListItems(index)}
                                        </ul>
                                        <div className="ftr">
                                            <div className="author">
                                                <a href={mailtoLink}>
                                                    <div className="stats">
                                                        <i className="far fa-hand-point-right"></i>
                                                        <span>Get Services Quotation</span>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section>
                <div id="content-block">
                    <div className="content-push">
                        <div className="parallax-slide">
                            <div className="parallax-clip">
                                <div className="fixed-parallax" style={{ backgroundImage: `url(${agriserviceImage1})` }}>
                                </div>
                            </div>
                        </div>


                        <div className="parallax-slide">
                            <div className="parallax-clip">
                                <div className="fixed-parallax" style={{ backgroundImage: `url(${agriserviceImage2})` }}>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

const getCategoryIcon = (index) => {
    const icons = ['fa-seedling', 'fa-spa', 'fa-money-check-alt'];
    return `fas ${icons[index - 1]}`;
};

const getServiceTitle = (index) => {
    const titles = ['Agronomics Services', 'Specialty Services', 'Technical Services'];
    return titles[index - 1];
};

const getServiceDescription = (index) => {
    const descriptions = [
        'Global leader Consultation matters agronomics and related products.',
        'We provide unique services in varied fields of agribusiness based on your needs. Sample include:',
        'Agricultural technology services of all ranges are accessible from any location.',
    ];
    return descriptions[index - 1];
};

const getServiceListItems = (index) => {
    const listItems = [
        ['Green houses', 'Marketing and market Research', 'Desert Farming'],
        ['Green houses', 'Marketing and market Research', 'Desert Farming'],
        ['Green houses', 'Marketing and market Research', 'Desert Farming'],
    ];
    return listItems[index - 1].map((item, i) => (
        <li className="list-group-item" style={{ borderStyle: 'none' }} key={i}>
            <i className="fas fa-leaf" style={{ color: '#469c2d' }}></i>
            <span>&nbsp; {item}</span>
        </li>
    ));
};

export default Agribusiness;
