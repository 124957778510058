// src/components/Navbar.js
import React from 'react';
import logo from '../assets/img/cititech-Logo.jpg';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-md navbar-fixed-top navigation-clean-button navbar-light" style={{ background: '#fff', boxShadow: 'inset 0px 0px' }}>
      <div className="container">
        <div>
          <a className="navbar-brand" href="#">
            <span style={{ color: '#487091' }}>
              <img src={logo} style={{ height: '50px' }} alt="CitiTech Logo" />
              CitiTech
            </span>
          </a>
          <button className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navcol-1">
            <span className="visually-hidden">Toggle navigation</span>
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div className="collapse navbar-collapse" id="navcol-1">
          <ul className="navbar-nav navbar-right">
            <li className="nav-item"><Link to="/" className="nav-link active">Home</Link></li>
            <li className="nav-item dropdown">
              <Link to="#" className="dropdown-toggle nav-link" aria-expanded="false" data-bs-toggle="dropdown">Product &amp; Services</Link>
              <div className="dropdown-menu" style={{ background: '#ffffff' }}>
                <Link to="rdservices" className="dropdown-item">Research and Data services</Link>
                <Link to="experts" className="dropdown-item">Experts</Link>
                <Link to="environmental" className="dropdown-item">Environment</Link>
                <Link to="services" className="dropdown-item">ICT Services</Link>
                <Link to="agribusiness" className="dropdown-item">Agribusiness</Link>
              </div>
            </li>
            <li className="nav-item"><Link to="about" className="nav-link">About us</Link></li>
            <li className="nav-item"><Link to="contact" className="nav-link">Contact</Link></li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
