// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import mainlogo from '../assets/img/cititech-Logo.jpg';

const Footer = () => {
    // Specify the email address and other details
    const emailAddress = 'gichehat@gmail.com';
    const subject = 'Inquiry';
    const body = 'Hello, I have an enquiry...';

    // Construct the mailto link
    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

  return (
    <footer id="myFooter">
      <div className="container-fluid">
        <div className="row text-center">
          <div className="col-12 col-sm-6 col-md-3">
            <h1 className="logo" style={{ marginTop: '30px' }}>
              <img src={mainlogo} style={{ height: '50px' }} alt="CitiTech Logo" />
              <a href="#">&nbsp;CitiTech&nbsp;</a>
            </h1>
            <div>
              <p style={{color: "#ffffff"}}>CITI for Technology &amp; Innovation (CITI) propels businesses forward with expert consultancy in research, data, agribusiness, and technology. Transform ideas into reality with CITI's collaborative solutions for global success.</p>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-2">
            <h5>Company</h5>
            <ul>
              <li><a href="#">Home</a></li>
              <li><a href="/about">About Us</a></li>
              <li><a href="/services">Services</a></li>
            </ul>
          </div>
          <div className="col-12 col-sm-6 col-md-2">
            <h5>Others</h5>
            <ul>
              <li><a href="#">Company Information<br /></a></li>
              <li><a href="#">Trainings</a></li>
              <li><a href="/contact">Contacts</a></li>
            </ul>
          </div>
          <div className="col-sm-6 col-md-2">
            <h5>Quick Links</h5>
            <ul>
              <li><a href="#">Company News</a></li>
              <li><Link to={mailtoLink}>Support Desk</Link></li>
              <li><a href="#">Our Community</a></li>
            </ul>
          </div>
          <div className="col-md-3 social-networks">
            <div></div>
            <a className="envelope" href="#"><i className="fas fa-envelope"></i></a>
            <a className="whatsapp" href="#"><i className="fab fa-whatsapp"></i></a>
            <a className="linkedin" href="#"><i className="fab fa-linkedin-in"></i></a>
            <a className="google" href="#"><i className="fab fa-youtube"></i></a>
            <a className="linkedin" href="#"><i className="fab fa-twitter"></i></a>
            <a className="linkedin" href="#"><i className="fab fa-facebook"></i></a>
            <Link to={'/contact'}><button className="btn btn-primary" style={{ marginTop: '20px' }} type="button">Contact us</button></Link>
          </div>
        </div>
        <div className="row footer-copyright">
          <div className="col">
            <p>Copyright © 2024 CitiTech.org. All Rights Reserved<a href="#"></a></p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
