// src/AppRouter.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Services from './pages/Services';
import Environmental from './pages/Environmental';
import Experts from './pages/Experts';
import Agribusiness from './pages/Agribusiness';
import RDServices from './pages/RDServices';
import ContactPage from './pages/Contact';
import About from './pages/About'; 

const AppRouter = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/environmental" element={<Environmental />} />
        <Route path="/experts" element={<Experts />} />
        <Route path="/agribusiness" element={<Agribusiness />} />
        <Route path="/rdservices" element={<RDServices />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/about" element={<About />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default AppRouter;
