import React from 'react';
import { Link } from 'react-router-dom';
const About = () => {
    return (
        <section className="about-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 about-text">
                        <div className="section-heading space-overflow">
                            <h1 className="section-title">
                                <span>About Us</span>
                            </h1>
                        </div>
                        <div className="space-t-40 space-b-40">
                            <p>
                                Historically, the world has interacted and developed through
                                products and services. The ultimate price tag attached to either
                                product or service is determined by how efficiently it has been
                                produced. Clivebridge International for Technology & Innovation
                                or CITI for short is a product developed to assist in increasing
                                clients’ production efficiency through the provision of
                                consultancy services by identifying and collaborating with a
                                pool of extremely qualified resource people in different sectors
                                who work together to develop a client tailor-made solution to
                                individual person or firm need. Thus CITI becomes “you” making
                                CITI the client’s business. A client’s business could be at the
                                start-up stage, upward or downward growth phases, or plateau
                                stage making CITI everyone’s company.
                            </p>
                        </div>
                        <Link className="custom-btn-nav" to={'#moretab'}>
                            READ MORE
                        </Link>
                    </div>

                    <div className="col-lg-8 col-md-12 about_img space-60 p-0">
                        <div className="about-image-box">
                            <div className="shape-1"></div>
                            <div className="shape-2"></div>
                            <div className="about-inner-image-box">
                                {/* <div className="layout-33"></div> */}
                            </div>
                            {/* <img src={cititechabout} style={{ marginTop: '1px' }} alt="About Us" /> */}
                        </div>
                    </div>
                </div>
            </div>

            <section className="py-5 header" id="moretab">
                <div className="container py-4">
                    <header className="text-center mb-5 pb-5 text-white">
                        <h1 className="display-4">within CitiTech </h1>
                        <p className="font-italic mb-1 text-white">
                            We are driven by a series of objectives, a mission, and vision. They keep CitiTech alive and vibrant.
                        </p>
                        <p className="font-italic">
                            {/* <a className="text-white" href="#">
          <u>cititech</u>
        </a> */}
                        </p>
                    </header>

                    <div className="row">
                        <div className="col-md-3">
                            <div
                                className="nav flex-column nav-pills nav-pills-custom"
                                id="v-pills-tab"
                                role="tablist"
                                aria-orientation="vertical"
                            >
                                <a
                                    className="nav-link mb-3 p-3 shadow active bg-success"
                                    id="v-pills-home-tab"
                                    data-bs-toggle="pill" // Updated Bootstrap 5 attribute
                                    href="#v-pills-home"
                                    role="tab"
                                    aria-controls="v-pills-home"
                                    aria-selected="true"
                                >
                                    <i className="fas fa-binoculars icon mr-2"></i>
                                    <span className="font-weight-bold small text-uppercase text-white">
                                        Mission
                                    </span>
                                </a>
                                <a
                                    className="nav-link mb-3 p-3 shadow bg-success"
                                    id="v-pills-vision-tab"
                                    data-bs-toggle="pill" // Updated Bootstrap 5 attribute
                                    href="#v-pills-vision"
                                    role="tab"
                                    aria-controls="v-pills-vision"
                                    aria-selected="false"
                                >
                                    <i className="fas fa-eye icon mr-2"></i>
                                    <span className="font-weight-bold small text-uppercase text-white">
                                        Vision
                                    </span>
                                </a>
                            </div>
                        </div>

                        <div className="col-md-9">
                            <div className="tab-content" id="v-pills-tabContent">
                                {/* Mission Tab Content */}
                                <div
                                    className="tab-pane fade shadow rounded bg-white show active p-5"
                                    id="v-pills-home"
                                    role="tabpanel"
                                    aria-labelledby="v-pills-home-tab"
                                >
                                    <h4 className="font-italic mb-4 text-dark">Mission</h4>
                                    <p className="font-italic text-muted mb-2">
                                        We strive to solve the development puzzle and walk the path with our clients
                                    </p>
                                </div>

                                {/* Vision Tab Content */}
                                <div
                                    className="tab-pane fade shadow rounded bg-white p-5"
                                    id="v-pills-vision"
                                    role="tabpanel"
                                    aria-labelledby="v-pills-vision-tab"
                                >
                                    <h4 className="font-italic mb-4 text-dark">Vision</h4>
                                    <p className="font-italic text-muted mb-2">
                                        To be the preferred partner to our clients in the field of development consultancy. This can only be achieved through well-executed collaborative working amongst resource people in different development sectors. CITI has achieved this by establishing excellent working relations with local and international professionals in diverse development sectors.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    );
};

export default About;
