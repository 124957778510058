// src/components/EnvironmentalSection.js
import React from 'react';
import environImage from '../assets/img/cititech-Logo.jpg';
import { Link } from 'react-router-dom';
import '../assets/css/environ.css';

const EnvironmentalSection = () => {
  return (
    <>
      <section className="about-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 about-text">
              <div className="section-heading space-overflow">
                <h1 className="section-title">
                  <span>Environment</span>
                </h1>
                <h4 className="section-description">
                  We offer expert consultancy in a range of environmental-related issues, including ecological surveys.
                </h4>
              </div>
              <div className="space-t-40 space-b-40">
                <p>
                  Impact assessment, monitoring, research, and outreach services. We assist our clients in meeting conditions necessary to be awarded international accreditation such as the ISO Certification.
                </p>
              </div>
              <Link className="custom-btn-nav" to="https://crasal.com/" target={'_blank'}>
                READ MORE
              </Link>
            </div>

            <div className="col-lg-6 col-md-12 about_img space-60">
              <div className="about_img-inner">
                <img src={environImage} className="img-fluid" alt="img" />
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default EnvironmentalSection;
