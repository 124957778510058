import React, { useState } from 'react';
import "../assets/css/contact.css";

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    phone: '',
    message: '',
  });

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formSpreeEndpoint = 'https://formspree.io/f/xleqeoqp';

    try {
      const response = await fetch(formSpreeEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setModalContent('Form submitted successfully! We will reach out to you as soon as possible.');
        alert('Form submitted successfully!');
      } else {
        setModalContent('Failed to submit form. Please try again later.');
        alert('Failed to submit form. Please try again later.');
      }

      // Display the modal
      setShowModal(true);

      // Clear the form after submission
      setFormData({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: '',
      });
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <section className="contact-page-section">
      <div className="container">
        <div className="sec-title">
          <div className="title">Contact Us</div>
          <h2>Let's Get in Touch.</h2>
        </div>
        <div className="inner-container">
          <div className="row clearfix">
            {/* Form Column */}
            <div className="form-column col-md-8 col-sm-12 col-xs-12">
              <div className="inner-column">
                {/* Contact Form */}
                <div className="contact-form">
                  <form method="post" id="contact-form" onSubmit={handleSubmit}>
                    <div className="row clearfix">
                      <div className="form-group col-md-6 col-sm-6 co-xs-12">
                        <input
                          type="text"
                          name="name"
                          value={formData.name}
                          placeholder="Name"
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="form-group col-md-6 col-sm-6 co-xs-12">
                        <input
                          type="email"
                          name="email"
                          value={formData.email}
                          placeholder="Email"
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="form-group col-md-6 col-sm-6 co-xs-12">
                        <input
                          type="text"
                          name="subject"
                          value={formData.subject}
                          placeholder="Subject"
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="form-group col-md-6 col-sm-6 co-xs-12">
                        <input
                          type="text"
                          name="phone"
                          value={formData.phone}
                          placeholder="Phone"
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="form-group col-md-12 col-sm-12 co-xs-12">
                        <textarea
                          name="message"
                          value={formData.message}
                          placeholder="Message"
                          onChange={handleInputChange}
                        ></textarea>
                      </div>
                      <div className="form-group col-md-12 col-sm-12 co-xs-12">
                        <button type="submit" className="theme-btn btn-style-one">
                          Send Now
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                {/* End Contact Form */}
              </div>
            </div>

            {/* Info Column */}
            <div className="info-column col-md-4 col-sm-12 col-xs-12">
              <div className="inner-column">
                <h2>Contact Info</h2>
                <ul className="list-info">
                  <li>
                    <i className="fas fa-globe"></i>P.O BOX ccc, 00200, NAIROBI.
                  </li>
                  <li>
                    <i className="far fa-envelope"></i>contact.cititech@info.co.ke
                  </li>
                  <li>
                    <i className="fas fa-phone"></i>1-234-567-890 <br /> 1-234-567-890
                  </li>
                </ul>
                <ul className="social-icon-four">
                  <li className="follow">Follow on: </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <p>{modalContent}</p>
          </div>
        </div>
      )}
    </section>
  );
};

export default ContactPage;
